 <template>
  <div class="clue-distribution">
    <!-- <search-bar-new
      :placeholder-text="$t('请输入客户姓名/电话/顾问')"
      :active="Object.values(filterParams).length > 0"
      :str="searchStr"
      :showScreen="true"
      :showCategory="false"
      @input="onInput"
      @search="onRefresh"
      @screening="filterPanelVisible = true">
    </search-bar-new> -->
  
    <!-- 筛选面板 -->
    <!-- <FilterPanel
      ref="filterPanel"
      v-model="filterPanelVisible"
      :options="filterOptions"
      @submit="onFilter"
    /> -->
  
    <!-- <VanTabs
      :queryType="activeTab"
      :isTotal="false"
      :tabList="tabList"
      @change="changeTab">
    </VanTabs> -->
  
    <p class="total-count">{{$t('共')}} {{ totalCount }} {{$t('条数据')}}</p>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <InvalidCardItem :item-data="item" :isUnReview="commonFilterParams.queryType === 0" 
          @change="onCheckChanged"
          @click="onItemClick"/>
      </template>
    </List>
  
    <div v-if="commonFilterParams.queryType === 0" class="btns-bar">
        <van-checkbox v-model="isAll" @click="isAllChange">{{$t('全选')}}</van-checkbox>
        <van-button square color="#EED484" @click="onReview">
          {{$t('批量审核')}}{{ distributionIds.length ? `(${distributionIds.length})`:'' }}
        </van-button>
    </div>
    <!-- 审核弹框 -->

    <van-dialog v-if="clientType == 1" v-model="visible" show-cancel-button :before-close="handlerConfirm" 
      confirmButtonColor="#B9921A" 
      :cancel-button-text="$t('取消')" 
      :confirm-button-text="$t('确认')"
    >
      <ReviewForm v-if="visible" ref="fromRef" :applyUserRole="distributionRole"></ReviewForm>
    </van-dialog>
    <PortalReviewFrom
      v-else
      v-model="visible"
      :type="2"
      :checkedItems="distributionIds"
      @confirm="()=>{
        onRefresh()
        $emit('refresh')
      }"
    />
  </div>
  </template> 
<script>
import { mapGetters } from 'vuex'
import SearchBarNew from '@/components/search-bar-new'
import VanTabs from '@/components/VanTabs'
import FilterPanel from '@/components/filter-panel'
import List from '@/components/baseList/list.vue'
import InvalidCardItem from '@/modules/invalidManage/invalidCardItem.vue'
import ReviewForm from '@/modules/invalidManage/reviewForm.vue'
import PortalReviewFrom from '@/modules/clue-incubation/details/reviewFrom.vue'
import loading from '@/utils/loading'
import clueDefeatServices from '@/services/clueDefeatServices.js'
import invalidManageServices from '@/services/invalidManageServices.js'
import { v4 as uuidv4 } from 'uuid'
import baseDataServices from '@/services/baseDataServices'
import formatParams from '@/utils/formatParams'
import mixins from '../mixins.js'

export default {
  name:'InvalidManage',
  components:{ SearchBarNew, VanTabs, FilterPanel, List, InvalidCardItem, ReviewForm, PortalReviewFrom },
  mixins: [mixins],
  data(){
    return {
      searchStr:'',
      filterPanelVisible:false,
      filterOptions:[],
      activeTab:0,
      tabList:[
        { text: this.$t('待审核'), value: 0,total:0, key:'unReviewed' },
        { text: this.$t('已审核'), value: 1, total: 0 , key:'reviewed' },
      ],
      distributionIds:[],
      list:[],
      totalCount: 0,
      filterParams: {}, 
      visible:false
    }
  },
  computed: {
    ...mapGetters([
      'dictHash',
    ]),
    // distributionOptions(){
    //   return [{
    //     name: '',
    //     type: 'B_PEOPLE_SELECTION',
    //     field: 'saIdList',
    //     isSelection: true,
    //     valueType: 'object',
    //     shopIds:'',
    //     height:'80vh',
    //     roleCodes: [this.activeTab===1?'1014001':'1014002'] // 角色对应的code
    //   }]
    // },
    isAll() {
      return this.distributionIds.length===this.list.length && this.distributionIds.length!==0
    },
    clientType() {
      return this.$store.getters.userInfo.type
    }
  },
  watch: {
    dictHash: {
      immediate: true,
      handler(val) {
        if (val) {
          this.createFilterOptions()
        }
      },
    }
  },
  activated() {
    this.onRefresh()
  },
  methods:{
    fetchApi(obj={}){
      const {
        reviewTimeStart,
        reviewTimeEnd,
        applyTimeStart,
        applyTimeEnd,
        ...o
      } = this.commonFilterParams
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        type: 2, // 无效
        applyTime: {
          start: applyTimeStart,
          end: applyTimeEnd,
        },
        reviewTime: {
          start: reviewTimeStart,
          end: reviewTimeEnd,
        },
        ...o,
      }
      loading.showLoading()
      return clueDefeatServices.getclueList(params).then(res => {
        loading.hideLoading()
        res.dataList.forEach((item)=>{
          item.checked=false
        })
        this.list = this.$refs.list.list.concat(res.dataList)
        console.log('this.list:',this.list)
        this.totalCount = res.page.totalCount
        return res
      })
    },
    onInput(value){
      this.searchStr = value
    },
    onRefresh(){
      this.distributionIds = []
      this.$refs.list.onRefresh()
    },
    onFilter(params){
      this.filterParams = formatParams(params, false)
      this.filterPanelVisible = false
      this.onRefresh()
    },
    // 切换 tab需要重新请求列表数据
    changeTab(val){
      console.log('val:',val)
      this.distributionIds = []
      this.activeTab=val
      this.onRefresh()
    },
    onReview(){
      if (!this.distributionIds.length) {
        this.$toast(this.$t('请选择待审核线索'))
        return false
      }
      this.visible = true
    },
    // 全选/取消全选
    isAllChange(){
      // if (this.isAll){
      //   // 默认勾选当前列表第一条线索的角色
      //   // 只勾选同类型角色跟进的线索，其他角色的取消勾选
      //   if (!this.$refs.list.list.length) {
      //     return
      //   }
      //   this.distributionRole = this.$refs.list.list[0].applyUserRole
      //   const ids = []
      //   this.$refs.list.list.forEach(item=>{
      //     if (item.applyUserRole === this.distributionRole) {
      //       item.checked = true
      //       ids.push(item.id)
      //     }
      //     else item.checked = false
      //   })
      //   this.distributionIds = ids
      // } else {
      //   this.distributionRole = ''
      //   this.distributionIds = []
      //   this.$refs.list.list.forEach(item=>{
      //     item.checked = false
      //   })
      // }
      const flag = !this.isAll
      this.distributionRole = this.distributionIds.length > 0 ? (this.$refs.list.list.find(({ id }) => { return id === this.distributionIds[0] })?.applyUserRole) : this.$refs.list.list[0].applyUserRole
      const ids = []
      this.$refs.list.list.forEach(item=>{
        if (item.applyUserRole === this.distributionRole) {
          item.checked = flag
          if (item.checked) {
            ids.push(item.id)
          }
        }
        else item.checked = false
      })
      this.distributionIds = ids
    },
    onCheckChanged(checked,theClue){
      const { applyUserRole, id } = theClue
      if (checked){
        if (!this.distributionRole) this.distributionRole = applyUserRole
        else if (this.distributionRole!==applyUserRole){
          return this.$toast(this.$t('请选择同类型角色跟进的线索'))
        }
      }
      const ids = []
      this.$refs.list.list.forEach(item=>{
        if (item.id === id){
          item.checked=checked
        }
        if (item.checked) ids.push(item.id)
      })
      this.distributionIds = ids
      if (!this.distributionIds.length) this.distributionRole = ''
    },
    async save() {
      const params = this.$refs.fromRef.getData()
      params.type=2
      params.ids = this.distributionIds
      const result = await clueDefeatServices.failedApproval(params)
      this.$toast(this.$t('审核成功'))
      this.onRefresh()
      this.$emit('refresh')
    },
    handlerConfirm(action, done) {
      console.log('handlerConfirm:',action,done)
      if (action === 'confirm') {
        this.$refs.fromRef.validate().then(async () => {
          await this.save()
          done()
        }).catch(() => {
          done(false)
        })
      } else {
        done()
      }
    },
    // 组件搜索框数据
    async createFilterOptions() {
      let ret = []
  
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('申请时间'),
        start: {
          field: 'applyTimeStart',
        },
        end: {
          field: 'applyTimeEnd',
        },
      })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('审核时间'),
        start: {
          field: 'reviewTimeStart',
        },
        end: {
          field: 'reviewTimeEnd',
        },
      })
      // const channelList = await baseDataServices.getAllChannelList()
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '来源渠道',
      //   field: 'channelOneId',
      //   multiple: true,
      //   options: channelList.map((item) => ({ ...item, label: item.name, value: item.id })),
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'channelTwoId',
      //   multiple: true,
      //   options: [],
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'channelThreeId',
      //   multiple: true,
      //   options: [],
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'channelFourId',
      //   multiple: true,
      //   options: [],
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'channelFiveId',
      //   multiple: true,
      //   options: [],
      // })
      // 渠道，新版
      ret.push({
        id: uuidv4(),
        type: 'channels',
        label: this.$t('来源渠道'),
        field: 'channels',
        tagsInput: true,
        fields: ['channelOneId', 'channelTwoId', 'channelThreeId', 'channelFourId', 'channelFiveId'],
      })
      // 无效原因
      const failCodesList = await invalidManageServices.getReasonList({})
      console.log('failCodesList:',failCodesList)
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('无效原因'),
        multiple: true,
        field: 'failCodes',
        options: failCodesList.map(item => {
          return {
            id: uuidv4(),
            label: item.reason,
            value: item.id,
          }
        }),
      })
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: '',
        field: 'failCodeChildren',
        multiple: true,
        options: [],
      })
      const dictList = this.getDictNameByCode(1013, '', 'array').filter(item => item.code !== '1013001')
  
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('审核结果'),
        multiple: true,
        field: 'reviewStatuses',
        options: dictList.map(item => {
          return {
            id: uuidv4(),
            label: item.name,
            value: item.code,
          }
        }),
      })
  
      this.filterOptions = ret
    },
    // 跳转至详情页
    onItemClick({ id,leadId,applyUserRole,leadTransferFlag }){
      if (this.clientType === 1) {
        if (leadTransferFlag===1){
          return this.$toast(this.$t('线索已转出至其他门店，无法查看'))
        }
        this.$router.push({
          name:'clueInvalidDetail',
          query:{
            id,
            leadId,
            applyUserRole,
            isUnReview: this.commonFilterParams.queryType === 0,
          }
        })
      } else {
        this.$router.push({ path: '/clue-incubation/details?id=' + leadId + '&origin=approveManage' })
      }
    }
  }
  
}
</script>
  <style lang="less" scoped>
  .total-count{
    color: #0d171a;
    padding: 10px 16px 0 16px;
    font-size: 12px;
  }
  .btns-bar{
    width: 100%;
    position: absolute;
    justify-content: space-between;
    bottom: 0px;
    display: flex;
    background: #fff;
    padding-left: 16px;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);

    /deep/.van-button{
      color: #0D171A !important;
      font-weight: 500;
    }
  }
  </style>
  