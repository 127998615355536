<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'

export default {
  components:{ shortConsumerInfo },
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    checked: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      customerOption : {
        name: 'customerName',
        sex: 'customerSex',
        phone: 'customerMobile',
      }
    }
  },
  computed:{
    getTypeName() {
      const { appointmentType } = this.dataSource
      const obj={
        2001001:this.$t('预约试驾'),
        2001002:this.$t('预约到店')
      }
      return appointmentType && obj[appointmentType] || ''
    },
  },
  methods:{
    goDetail(item) {
      this.$emit('goDetail',item)
    }, 
    onClick() {
      this.$emit('change', !this.dataSource.checked, { ...this.dataSource })
    },   
  }
}
</script>
<template>
  <div class="item-content">
    <div class="left" @click.stop="goDetail(dataSource)">
      <div class="user-status">
        <shortConsumerInfo :data="dataSource" :showLevel="false" :showIntention="false" :showSex="true" :option="customerOption"></shortConsumerInfo>
        <span v-if="!checked" :class="['status']" :style="{ color: { 2: '#029640', 1: '#FA5410' }[dataSource.reviewStatus] }">
          {{ { 2: '通过', 1: '驳回'}[dataSource.reviewStatus] }} 
        </span>
      </div>
      <template>
        <div class="van-ellipsis info-item">
          <span class="label">{{$t('试驾车型')}}：</span>
          <span>{{ dataSource.seriesName }} | {{dataSource.vehicleName}}</span>
        </div>
        <div class="van-ellipsis info-item">
          <span class="label">{{$t('试驾场景')}}：</span>
          <span>{{dataSource.type | codeFormat}}</span>
        </div>
        <div class="van-ellipsis info-item">
          <span class="label">{{$t('试驾地点')}}：</span>
          <span>{{dataSource.driveProvinceName + dataSource.driveCityName + dataSource.driveDistrictName + dataSource.driveAddress}}</span>
        </div>
        <div class="van-ellipsis info-item">
          <span class="label">{{$t('预约试驾时间')}}：</span>
          <span>{{dataSource.appointmentTime}}</span>
        </div>
        <div class="van-ellipsis info-item">
          <span class="label">{{$t('销售顾问')}}：</span>
          <span>{{dataSource.followUserName}}</span>
        </div>
      </template>
    </div>
    <div v-if="checked" class="right">
      <van-checkbox :value="dataSource.checked" shape="square" @click.stop="onClick"></van-checkbox>
    </div> 
  </div>
</template>
<style lang="less" scoped>
@checkbox-color: #0D171A;
.item-content{
  display: flex;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 16px 12px 12px 12px;
  font-size: 13px;
  .left {
    flex: 1;
    width: 80%;
    color: @checkbox-color;
    font-size: 13px;
    line-height: 16px;
    .user-status{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
      padding-bottom: 8px;
      margin-bottom: 12px;
      .status{
        width: 70px;
        text-align: right;
      }
    }
    .info-item{
      margin-bottom:8px;
      .label{
        color: #929798;
      }
    }
  }

  .right {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    /deep/.van-checkbox__icon {
      font-size: 16px;

      .van-icon {
        border: 2px solid rgba(13,23,26,0.45);
      }
    }

    /deep/.van-checkbox__icon--checked .van-icon {
      background-color: @checkbox-color;
      border-color: @checkbox-color;
      line-height: 1;
    }
  }
}
</style>
