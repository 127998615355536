
<template>
  <div class="Item" >
    <div class="left" @click="$emit('click',itemData)">
      <div style="display: flex; justify-content: space-between;">
        <shortConsumerInfo class="van-ellipsis info-item" :data="itemData" :showLevel="false" :showIntention="false" :showSex="false" :option="customerOption"></shortConsumerInfo>
        <span v-if="!checked" :style="{ marginLeft: '8px', color: ['3001020', '3001025', '3001030'].includes(itemData.applyStatus) ? '#FA5410' : '#029640' }">{{ getDictNameByCode(3001, itemData.applyStatus) }}</span>
      </div>
      <template>
        <div class="van-ellipsis info-item">
          <div class="label">{{$t('订单编号')}}：</div>
          <div class="value">{{ itemData.appOrderId }}</div>
        </div>
        <div class="van-ellipsis info-item">
          <div class="label">{{$t('订单状态')}}：</div>
          <div class="value" style="color: #B9921A;">{{ itemData.orderStatus | codeFormat }}</div>
        </div>
        <div class="van-ellipsis info-item">
          <div class="label">{{$t('申请门店')}}：</div>
          <div class="value">{{ itemData.salesDealerName }}</div>
        </div>
        <div class="van-ellipsis info-item">
          <div class="label">{{$t('申请类型')}}：</div>
          <div class="value" style="color: #B9921A;">{{ itemData.type === 1 ? $t('订单修改') : $t('退单') }}</div>
        </div>
        <div class="van-ellipsis info-item flex">
          <div class="label">{{$t('申请人')}}：</div>
          <div class="van-ellipsis value">{{itemData.applyUserName}}</div>
        </div>
      </template>
    </div>
    <div v-if="checked" class="right">
      <van-checkbox :value="itemData.checked" shape="square" @click.stop="onClick"></van-checkbox>
    </div>
  </div>
  </template>
<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
export default {
  name: 'Item',
  components: {
    shortConsumerInfo
  },
  props: {
    itemData: {
      type: Object,
      default() {
        return {}
      }
    },
    checked: {
      type: Boolean,
      default() {
        return false
      }
    },
  
  },
  
  data() {
    return {
      customerOption : {
        name: 'customerName',
        sex: 'customerSex',
        phone: 'customerMobile',
      },
    }
  },
  methods: {
    onClick() {
      this.$emit('change', !this.itemData.checked, { ...this.itemData })
    },
  
  }
}
</script>
  <style lang="less" scoped>
  @checkbox-color: #0D171A;
  
  .Item {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 16px 12px 12px 12px;
    width: 100%;
    overflow: hidden;
    .left {
      flex: 1;
      width: 80%;
      color: @checkbox-color;
      font-size: 13px;
      line-height: 16px;
      overflow: hidden;
      .info-item{
        margin-bottom:8px;
        display: flex;
        width: 100%;
        &.flex {
          display: flex;
        }
        .label{
          color: #929798;
          width: 65px;
        }
        .value {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  
    .right {
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/.van-checkbox__icon {
        font-size: 16px;
  
        .van-icon {
          border: 2px solid rgba(13,23,26,0.45);
        }
      }
  
      /deep/.van-checkbox__icon--checked .van-icon {
        background-color: @checkbox-color;
        border-color: @checkbox-color;
        line-height: 1;
      }
    }
  }
  </style>
  