<template>
  <div class="Item">
    <div class="left" @click="$emit('click', itemData)">
      <div style="display: flex; justify-content: space-between;">
        <shortConsumerInfo
          :data="itemData"
          :showLevel="false"
          :showIntention="false"
          :showSex="false"
          :option="customerOption"
        ></shortConsumerInfo>
        <span v-if="!checked" :style="{ marginLeft: '8px', color: ['1028000'].includes(itemData.status) ? '#FA5410' : '#029640' }">{{ getDictNameByCode(1028, itemData.status) }}</span>
      </div>
      <template>
        <div class="van-ellipsis info-item">
          <span class="label">{{ $t("申请转入门店") }}：</span>
          <span>{{ itemData.targetDealerName }}</span>
        </div>
        <div class="van-ellipsis info-item flex">
          <span class="label">{{ $t("申请说明") }}：</span>
          <span class="van-ellipsis value">{{ itemData.remark }}</span>
        </div>
      </template>
    </div>
    <div v-if="checked" class="right">
      <van-checkbox
        :value="itemData.checked"
        shape="square"
        @click.stop="onClick"
      ></van-checkbox>
    </div>
  </div>
</template>
<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
export default {
  name: 'Item',
  components: {
    shortConsumerInfo,
  },
  props: {
    itemData: {
      type: Object,
      default() {
        return {}
      },
    },
    checked: {
      type: Boolean,
      default() {
        return false
      },
    },
  },

  data() {
    return {
      customerOption: {
        name: 'userName',
        sex: 'customerSex',
        phone: 'userMobile',
      },
    }
  },
  computed: {
    // checked() {
    //   return (
    //     (this.$store.getters.userInfo.type == 1 &&
    //       this.itemData.status == '1028010') ||
    //     (this.$store.getters.userInfo.type == 0 &&
    //       ['1028005', '1028015'].includes(this.itemData.status))
    //   )
    // },
  },
  methods: {
    onClick() {
      this.$emit('change', !this.itemData.checked, { ...this.itemData })
    },
  },
}
</script>
<style lang="less" scoped>
@checkbox-color: #0d171a;

.Item {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 16px 12px 12px 12px;

  .left {
    flex: 1;
    width: 80%;
    color: @checkbox-color;
    font-size: 13px;
    line-height: 16px;

    .info-item {
      margin-bottom: 8px;
      &.flex {
        display: flex;
      }
      .label {
        color: #929798;
      }
      .value {
        width: calc(100% - 65px);
      }
    }
  }

  .right {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    /deep/.van-checkbox__icon {
      font-size: 16px;

      .van-icon {
        border: 2px solid rgba(13, 23, 26, 0.45);
      }
    }

    /deep/.van-checkbox__icon--checked .van-icon {
      background-color: @checkbox-color;
      border-color: @checkbox-color;
      line-height: 1;
    }
  }
}
</style>
