<template>
  <div class="clue-defeat">
    <div class="totalCount">{{$t('共')}} {{ totalCount }} {{$t('条数据')}}</div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <Item
          :item-data="item"
          :checked="commonFilterParams.queryType === 0"
          @change="onCheckChanged"
          @click="handlerTo(item)"
        />
      </template>
    </List>

    <div v-if="commonFilterParams.queryType === 0" class="btns-bar">
      <van-checkbox :value="isAll" @click="isAllChange">{{
        $t("全选")
      }}</van-checkbox>
      <van-button square color="#EED484" @click="handlerAudit"
        >{{ $t("批量审核") }}
        {{
          checkedItems.length > 0 ? `(${checkedItems.length})` : ""
        }}</van-button
      >
    </div>

    <van-dialog
      v-model="visible"
      show-cancel-button
      :before-close="handlerConfirm"
      confirmButtonColor="#B9921A"
      :cancel-button-text="$t('取消')"
      :confirm-button-text="$t('确认')"
    >
      <DefeatFrom v-if="visible" ref="defeatFromRef"></DefeatFrom>
    </van-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterPanel from '@/components/filter-panel'
import DefeatFrom from './defeatFrom.vue'
import VanTabs from '@/components/VanTabs'
import List from '@/components/baseList/list.vue'
import Item from './item.vue'
import loading from '@/utils/loading'
import { reviewList, batchTransferReview } from '@/services/approveManage'
import { judgeMobile } from '@/utils/index'
import mixins from '../mixins.js'
export default {
  name: 'leadChangeInto',
  components: { FilterPanel, VanTabs, List, Item, DefeatFrom },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'clueDefeat')
    })
  },
  mixins: [mixins],
  data() {
    return {
      // 列表数据源集合
      list: [],
      // 审核选中项集合
      checkedItems: [],
      visible: false,
      totalCount: 0,
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
    judgeMobile,
    isAll() {
      return (
        this.checkedItems.length > 0 &&
        this.checkedItems.length ===
          this.list.filter(
            (item) => item.applyUserRole === this.checkedItems[0].applyUserRole
          ).length
      )
    },
  },
  activated() {
    this.onRefresh()
  },
  methods: {
    handlerConfirm(action, done) {
      if (action === 'confirm') {
        this.$refs.defeatFromRef
          .validate()
          .then(async () => {
            await this.save()
            done()
          })
          .catch(() => {
            done(false)
          })
      } else {
        done()
      }
    },
    async save() {
      const params = this.$refs.defeatFromRef.getData()
      const checkeds = this.checkedItems.map((c) => ({
        id: c.id,
        status: c.status,
      }))
      const batchReqs = checkeds.map((c) => ({
        ...c,
        ...params,
      }))
      const result = await batchTransferReview({ batchReqs })
      this.$toast(this.$t('审核成功'))
      this.onRefresh()
      this.$emit('refresh')
    },
    // 审核
    handlerAudit() {
      if (!this.checkedItems.length) {
        this.$toast(this.$t('请选择待审核线索'))
        return false
      }
      this.visible = true
    },
    // 全选/取消全选
    isAllChange() {
      const selectList = []
      const flag = !this.isAll
      this.$refs.list.list.map((item) => {
        const flagObj = this.checkedItems.length
          ? this.checkedItems[0].applyUserRole
          : this.$refs.list.list[0].applyUserRole
        // 全选时，只能选择相同角色类型的数据
        if (flagObj === item.applyUserRole) {
          item.checked = flag
          if (item.checked) {
            selectList.push(item)
          }
        }
      })
      this.checkedItems = selectList
    },
    handlerTo(item) {
      if (item.leadTransferFlag === 1 && this.userInfo.type == 1) {
        return this.$toast(this.$t('线索已转出至其他门店，无法查看'))
      }
      this.$router.push({
        path: '/clue-transfer-detail',
        query: {
          id: item.id,
        },
      })
    },
    onCheckChanged(checked, theClue) {
      if (
        this.checkedItems.length &&
        this.checkedItems[0].applyUserRole !== theClue.applyUserRole
      ) {
        this.$toast(this.$t('请选择相同类型角色申请的线索'))
        return
      }
      const selectList = []
      this.$refs.list.list.forEach((item) => {
        if (item.id === theClue.id) {
          item.checked = checked
        }
        if (item.checked) {
          selectList.push(item)
        }
      })
      this.checkedItems = selectList
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const { queryType, reviewStatuses = [], reviewTimeStart, reviewTimeEnd, applyTimeEnd, applyTimeStart, ...o } = this.commonFilterParams
      const params = {
        ...o,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        type: 1,
        reviewTime: {
          start: reviewTimeStart || '',
          end: reviewTimeEnd || ''
        },
        applyTime: {
          start: applyTimeStart || '',
          end: applyTimeEnd || '',
        },
        reviewStatus: queryType == 0 ? 1 : 2
      }
      if (reviewStatuses.length) {
        params.statuses = reviewStatuses
      }
      // if (reviewStatuses.length) {
      //   params.statuses = reviewStatuses
      // } else if (queryType == 1) {
      //   params.statuses = this.$store.getters.userInfo.type == 1 ? ['1028000', '1028005', '1028015', '1028100'] : ['1028100', '1028000']
      // } else if (queryType === 0) {
      //   if (this.$store.getters.userInfo.type == 1) {
      //     params.statuses = ['1028010']
      //   } else if (o.transferType == 1) {
      //     params.statuses = ['1028005']
      //   } else if (o.transferType == 2) {
      //     params.statuses = ['1028015']
      //   }
      // }
      loading.showLoading()
      return reviewList(params).then((res) => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        res.dataList.forEach((item) => {
          item.checked = false
        })
        if (obj.currentPage === 1) {
          this.list = res.dataList
          this.checkedItems = []
        } else {
          this.list = this.$refs.list.list.concat(res.dataList)
        }
        return res
      })
    },
    // 刷新
    onRefresh() {
      this.$refs.list.onRefresh()
    },
  },
}
</script>
<style lang="less" scoped>
.btns-bar {
  width: 100%;
  position: absolute;
  justify-content: space-between;
  bottom: 0px;
  display: flex;
  background: #fff;
  padding-left: 16px;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  /deep/.van-button {
    color: #0d171a !important;
    font-weight: 500;
    // width: 92px;
  }
}

.totalCount {
  color: @black;
  padding: 10px 16px 0 16px;
  font-size: 12px;
}
</style>
