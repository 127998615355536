<template>
  <div class="clue-defeat">
    <!-- <search-bar-new :placeholder-text="$t('请输入客户姓名/电话/顾问')" :active="Object.values(filterParams).length > 0" :str="searchStr" :showScreen="true" @input="onInput"
      @search="onRefresh" @screening="filterPanelVisible = true">
    </search-bar-new>
  
    <FilterPanel ref="filterPanel" v-model="filterPanelVisible" :options="filterOptions" @submit="onFilter" />
  
    <VanTabs :queryType="1" :tabList="tabList" :isTotal="false" @change="changeTab">
    </VanTabs> -->

    <div class="totalCount">{{$t('共')}} {{ totalCount }} {{$t('条数据')}}</div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <DefeatCardItem :item-data="item" @change="onCheckChanged" @click="handlerTo(item)" />
      </template>
    </List>
  
    <div v-if="commonFilterParams.queryType === 0" class="btns-bar">
      <van-checkbox :value="isAll" @click="isAllChange">{{$t('全选')}}</van-checkbox>
      <van-button square color="#EED484" @click="handlerAudit">{{$t('批量审核')}} {{ checkedItems.length > 0 ? `(${checkedItems.length})` :
        ''
      }}</van-button>
    </div>
  
    <van-dialog v-if="userInfo.type == 1" v-model="visible" show-cancel-button :before-close="handlerConfirm" confirmButtonColor="#B9921A"
      :cancel-button-text="$t('取消')" 
      :confirm-button-text="$t('确认')">
      <DefeatFrom v-if="visible" ref="defeatFromRef" :applyUserRole="checkedItems[0].applyUserRole"></DefeatFrom>
    </van-dialog>
    <PortalReviewFrom
      v-else
      v-model="visible"
      :type="1"
      :checkedItems="checkedItems.map(({ id })=>id)"
      @confirm="()=>{
        onRefresh()
        $emit('refresh')
      }"
    />
  </div>
  </template>
  
<script>
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import SearchBarNew from '@/components/search-bar-new'
import FilterPanel from '@/components/filter-panel'
import DefeatFrom from '@/modules/clueDefeat/defeatFrom.vue'
import PortalReviewFrom from '@/modules/clue-incubation/details/reviewFrom.vue'
import baseDataServices from '@/services/baseDataServices'
import formatParams from '@/utils/formatParams'
import VanTabs from '@/components/VanTabs'
import List from '@/components/baseList/list.vue'
import DefeatCardItem from '@/modules/clueDefeat/defeatCardItem.vue'
import loading from '@/utils/loading'
import ClueDefeatServices from '@/services/clueDefeatServices'
import { judgeMobile } from '@/utils/index'
import mixins from '../mixins.js'
import { reviewLeadFail } from '@/services/clueIncubationServices.js'

export default {
  name: 'clueDefeat',
  components: { SearchBarNew, FilterPanel, VanTabs, List, DefeatCardItem, DefeatFrom, PortalReviewFrom },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'clueDefeat')
    })
  },
  mixins: [mixins],
  data() {
    return {
      searchStr: '',
      filterPanelVisible: false,
      filterOptions: [],
      filterParams: {},
      activeTab: 1,
      tabList: [
        { text: this.$t('待审核'), value: 1, total: 0, key: 'pendingApproval' },
        { text: this.$t('已审核'), value: 2, total: 0, key: 'approved' },
      ],
      // 列表数据源集合
      list: [],
      // 审核选中项集合
      checkedItems: [],
      visible: false,
      totalCount: 0
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
    judgeMobile,
    isAll() {
      return this.checkedItems.length > 0 && this.checkedItems.length === this.list.filter(item => item.applyUserRole === this.checkedItems[0].applyUserRole).length
    }
  
  },
  watch: {
    dictHash: {
      immediate: true,
      handler(val) {
        if (val) {
          this.createFilterOptions()
        }
      },
    }
  },
  activated() {
    this.onRefresh()
  },
  methods: {
    handlerConfirm(action, done) {
      if (action === 'confirm') {
        this.$refs.defeatFromRef.validate().then(async () => {
          await this.save()
          done()
        }).catch(() => {
          done(false)
        })
      } else {
        done()
      }
    },
    async save() {
      const params = this.$refs.defeatFromRef.getData()
      params.ids = this.checkedItems.map(item => item.id)
      const result = await ClueDefeatServices.failedApproval(params)
      this.$toast(this.$t('审核成功'))
      this.onRefresh()
      this.$emit('refresh')
    },
    // 审核
    handlerAudit() {
      if (!this.checkedItems.length) {
        this.$toast(this.$t('请选择待审核线索'))
        return false
      }
      this.visible = true
    },
    // 全选/取消全选
    isAllChange() {
      const selectList = []
      const flag = !this.isAll
      this.$refs.list.list.map((item) => {
        const flagObj = this.checkedItems.length ? this.checkedItems[0].applyUserRole : this.$refs.list.list[0].applyUserRole
        // 全选时，只能选择相同角色类型的数据
        if (flagObj === item.applyUserRole) {
          item.checked = flag
          if (item.checked) {
            selectList.push(item)
          }
        }
      })
      this.checkedItems = selectList
    },
    handlerTo(item) {
      if (this.userInfo.type === 1) {
        if (item.leadTransferFlag===1){
          return this.$toast(this.$t('线索已转出至其他门店，无法查看'))
        }
        this.$router.push({
          path: '/clue-defeat-detail',
          query: {
            id: item.leadId,
            defeatId: item.id,
            applyUserRole: item.applyUserRole,
          },
        })
      } else {
        this.$router.push({ path: '/clue-incubation/details?id=' + item.leadId + '&origin=approveManage' })
      }
    },
    onCheckChanged(checked, theClue) {
      if (this.checkedItems.length && this.checkedItems[0].applyUserRole !== theClue.applyUserRole) {
        this.$toast(this.$t('请选择相同类型角色申请的线索'))
        return
      }
      const selectList = []
      this.$refs.list.list.forEach(item => {
        if (item.id === theClue.id) {
          item.checked = checked
        }
        if (item.checked) {
          selectList.push(item)
        }
      })
      this.checkedItems = selectList
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const {
        reviewTimeStart,
        reviewTimeEnd,
        applyTimeStart,
        applyTimeEnd,
        ...o
      } = this.commonFilterParams
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        type: 1, // 战败
        applyTime: {
          start: applyTimeStart,
          end: applyTimeEnd,
        },
        reviewTime: {
          start: reviewTimeStart,
          end: reviewTimeEnd,
        },
        ...o,
      }
      loading.showLoading()
      return ClueDefeatServices.getclueList(params).then(res => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        res.dataList.forEach((item) => {
          item.checked = false
        })
        if (obj.currentPage === 1) {
          this.list = res.dataList
          this.checkedItems = []
        } else {
          this.list = this.$refs.list.list.concat(res.dataList)
        }
        return res
      })
    },
    // 组件搜索框数据
    async createFilterOptions() {
      let ret = []
  
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('申请时间'),
        start: {
          field: 'applyTimeStart',
        },
        end: {
          field: 'applyTimeEnd',
        },
      })
      ret.push({
        id: uuidv4(),
        type: 'dataRange',
        dateType: 'datetime',
        label: this.$t('审核时间'),
        start: {
          field: 'reviewTimeStart',
        },
        end: {
          field: 'reviewTimeEnd',
        },
      })
      // const channelList = await baseDataServices.getAllChannelList()
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '027e67',
      //   field: 'channelOneId',
      //   multiple: true,
      //   options: channelList.map((item) => ({ ...item, label: item.name, value: item.id })),
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'channelTwoId',
      //   multiple: true,
      //   options: [],
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'channelThreeId',
      //   multiple: true,
      //   options: [],
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'channelFourId',
      //   multiple: true,
      //   options: [],
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'channelFiveId',
      //   multiple: true,
      //   options: [],
      // })
      // 渠道，新版
      ret.push({
        id: uuidv4(),
        type: 'channels',
        label: this.$t('来源渠道'),
        field: 'channels',
        tagsInput: true,
        fields: ['channelOneId', 'channelTwoId', 'channelThreeId', 'channelFourId', 'channelFiveId'],
      })
      // const failCodesList = await baseDataServices.defeatClueTree()
      const failCodesList = await baseDataServices.defeatClueTreeNew({ bizType: this.$store.getters.userInfo.type === 1 ? 1 : 2 })
      ret.push({
        id: uuidv4(),
        type: 'commonCascader',
        label: this.$t('战败原因'),
        field: 'failCode',
        tagsInput: true,
        options: failCodesList.map(item => {
          return {
            id: uuidv4(),
            name: item.reason,
            value: item.id,
            children: item.children && item.children.length ? item.children.map(item => {
              return {
                id: uuidv4(),
                name: item.reason,
                value: item.id,
                children: []
              }
            }): []
          }
        }),
        fields: ['failCode', 'failCodeChildren'],
      })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '战败原因',
      //   multiple: true,
      //   field: 'failCode',
      //   options: failCodesList.map(item => {
      //     return {
      //       id: uuidv4(),
      //       label: item.reason,
      //       value: item.id,
      //     }
      //   }),
      //   onChange: async (option) => {
      //     console.log('onChange', option, 11111)
      //     console.log(' this.filterOptions', this.filterOptions)
      //     const findItem = this.filterOptions.find(item => item.field === 'failCodeChildren')
      //     if (option && option.length) {
      //       const params = option.map(item => item.value)
      //       const failCodeChildren = await ClueDefeatServices.getDefeatReasonListonChildrenList({ ids: params })
      //       if (failCodeChildren && failCodeChildren.length) {
      //         findItem.options = failCodeChildren.map(item => {
      //           return {
      //             id: uuidv4(),
      //             label: item.reason,
      //             value: item.id,
      //           }
      //         })
      //       }
      //     } else {
      //       findItem.options = []
      //     }
      //   }
      // })
      // ret.push({
      //   id: uuidv4(),
      //   type: 'options',
      //   label: '',
      //   field: 'failCodeChildren',
      //   multiple: true,
      //   options: [],
      // })
      const dictList = this.getDictNameByCode(1013, '', 'array').filter(item => item.code !== '1013001')
  
      ret.push({
        id: uuidv4(),
        type: 'options',
        label: this.$t('审核结果'),
        multiple: true,
        field: 'reviewStatuses',
        options: dictList.map(item => {
          return {
            id: uuidv4(),
            label: item.name,
            value: item.code,
          }
        }),
      })
  
      this.filterOptions = ret
    },
    changeTab(val) {
      this.activeTab = val
      this.onRefresh()
    },
    onFilter(params) {
      this.filterParams = formatParams(params, false)
      this.filterPanelVisible = false
      this.onRefresh()
    },
    // 输入框键入
    onInput(value) {
      this.searchStr = value
    },
    // 刷新
    onRefresh() {
      this.$refs.list.onRefresh()
    },
  
  }
}
</script>
  <style lang="less" scoped>
  .btns-bar {
    width: 100%;
    position: absolute;
    justify-content: space-between;
    bottom: 0px;
    display: flex;
    background: #fff;
    padding-left: 16px;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  
    /deep/.van-button {
      color: #0D171A !important;
      font-weight: 500;
      // width: 92px;
    }
  }
  
  .totalCount {
    color: @black;
    padding: 10px 16px 0 16px;
    font-size: 12px;
  }
  </style>
  