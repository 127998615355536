<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      form: {
        result: '',
        refuseReason: '',
      },
      dictList: [
        {
          code: '2',
          name: '通过'
        },
        {
          code: '1',
          name: '驳回'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    getData() {
      return {
        reviewStatus: this.form.result,
        reviewRemark: this.form.refuseReason,
      }
    },
    async validate() {
      return this.$refs.formRef.validate()
    }
  }
}
</script>
<template>
<div class="defeat-from">
  <van-form ref="formRef" :show-error="false">

    <van-field v-model="form.result" required :name="$t('审核结果')" style="display: block;" :label="$t('审核结果')"
      :rules="[{ required: true, message: $t('请选择审核结果') }]">
      <template #input>
        <van-radio-group v-model="form.result" direction="horizontal">
          <van-radio v-for="item in dictList" :key="item.code" :name="item.code">{{ item.name }}</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <van-field v-model="form.refuseReason" :required="form.result == 1" style="display: block;" :name="$t('审核备注')" :label="$t('审核备注')"
      :placeholder="$t('请输入审核备注')" :rules="[{ required: form.result == 1, message: $t('请输入审核备注') }]" maxlength="50" />
  </van-form>
</div>
</template>

<style lang="less" scoped>
.defeat-from {
  padding: 20px;
}
</style>
