export default {
  props: {
    commonFilterParams: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    commonFilterParams: {
      handler() {
        this.onRefresh && this.onRefresh()
      },
      deep: true,
    },
  },
}
