<script>
import ItemView from './item.vue'
import List from '@/components/baseList/list.vue'
import { queryReviewList, batchOfflineReview } from '@/services/approveManage'
import { judgeMobile } from '@/utils/index'
import DefeatFrom from './defeatFrom.vue'
import mixins from '../mixins.js'
import { mapGetters } from 'vuex'
export default {
  components:{ ItemView,List, DefeatFrom },
  mixins: [mixins],
  data() {
    return {
      countNum:0,
      // 列表数据源集合
      list: [],
      // 审核选中项集合
      checkedItems: [],
      visible: false,
      totalCount: 0
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    isDealer() {
      return this.userInfo.type === 1 
    },
    judgeMobile,
    isAll() {
      return this.checkedItems.length > 0 && this.checkedItems.length === this.list.filter(item => item.applyUserRole === this.checkedItems[0].applyUserRole).length
    }
  },
  watch: {
    userInfo: {
      handler(val) {
        if (val.id) {
        }
      },
      immediate: true,
      deep: true
    },
  },
  activated() {
    this.onRefresh()
  },
  methods:{
    fetchApi(obj={}) {
      if (!this.userInfo.id) {
        return 
      }
      const {
        reviewTimeStart = '',
        reviewTimeEnd = '',
        applyTimeStart = '',
        applyTimeEnd = '',
        salesUserIds = [],
        reviewStatuses = [],
        queryType,
        ...o
      } = this.commonFilterParams
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        applyTime: {
          start: applyTimeStart,
          end: applyTimeEnd,
        },
        reviewTime: {
          start: reviewTimeStart,
          end: reviewTimeEnd,
        },
        applyUserIds: salesUserIds,
        ...o
      }
      if (reviewStatuses.length) {
        params.statuses = reviewStatuses
      } else if (queryType == 1) {
        params.statuses = this.isDealer ? [3, 5, 10] : [5, 10]
      } else if (queryType === 0) {
        params.statuses = this.isDealer ? [1] : [3]
      }
      return queryReviewList(params).then((res) => {
        this.totalCount = res.page.totalCount
        res.dataList.forEach((item) => {
          item.checked = false
        })
        if (obj.currentPage === 1) {
          this.list = res.dataList
          this.checkedItems = []
        } else {
          this.list = this.$refs.list.list.concat(res.dataList)
        }
        return res
      })
    },
    goDetail(query={}){
      this.$router.replace({
        path: '/offlineOrder/detail',
        query
      }) 
    },
    // 刷新
    onRefresh() {
      this.$refs.list.onRefresh()
    },
    async save() {
      const params = this.$refs.defeatFromRef.getData()
      const batchReqs = this.checkedItems.map(c => ({
        id: c.id,
        status: params.result,
        ...params
      }))
      const result = await batchOfflineReview({ batchReqs })
      this.$toast(this.$t('审核成功'))
      this.onRefresh()
      this.$emit('refresh')
    },
    handlerConfirm(action, done) {
      if (action === 'confirm') {
        this.$refs.defeatFromRef.validate().then(async () => {
          await this.save()
          done()
        }).catch(() => {
          done(false)
        })
      } else {
        done()
      }
    },
    // 审核
    handlerAudit() {
      if (!this.checkedItems.length) {
        this.$toast(this.$t('请选择待审核线索'))
        return false
      }
      this.visible = true
    },
    // 全选/取消全选
    isAllChange() {
      const selectList = []
      const flag = !this.isAll
      this.$refs.list.list.map((item) => {
        const flagObj = this.checkedItems.length ? this.checkedItems[0].applyUserRole : this.$refs.list.list[0].applyUserRole
        // 全选时，只能选择相同角色类型的数据
        if (flagObj === item.applyUserRole) {
          item.checked = flag
          if (item.checked) {
            selectList.push(item)
          }
        }
      })
      this.checkedItems = selectList
    },
    onCheckChanged(checked, theClue) {
      if (this.checkedItems.length && this.checkedItems[0].applyUserRole !== theClue.applyUserRole) {
        this.$toast(this.$t('请选择相同类型角色申请的线索'))
        return
      }
      const selectList = []
      this.$refs.list.list.forEach(item => {
        if (item.id === theClue.id) {
          item.checked = checked
        }
        if (item.checked) {
          selectList.push(item)
        }
      })
      this.checkedItems = selectList
    },
  }
}
</script>
<template>
  <div>
    <div class="totalCount">{{$t('共')}} {{ totalCount }} {{$t('条数据')}}</div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <ItemView :dataSource="item" 
          :checked="commonFilterParams.queryType === 0"
          @goDetail="(row)=>{
            goDetail({id: row.id})
          }"
          @change="onCheckChanged"
        />
      </template>
    </List>
    <!-- <div v-if="commonFilterParams.queryType === 0" class="btns-bar">
      <van-checkbox :value="isAll" @click="isAllChange">{{$t('全选')}}</van-checkbox>
      <van-button square color="#EED484" @click="handlerAudit">{{$t('批量审核')}} {{ checkedItems.length > 0 ? `(${checkedItems.length})` :
        ''
      }}</van-button>
    </div> -->
  
    <van-dialog v-model="visible" show-cancel-button :before-close="handlerConfirm" confirmButtonColor="#B9921A"
      :cancel-button-text="$t('取消')" 
      :confirm-button-text="$t('确认')">
      <DefeatFrom v-if="visible" ref="defeatFromRef"></DefeatFrom>
    </van-dialog>
  </div>
</template>
<style lang="less" scoped>
  .btns-bar {
    width: 100%;
    position: absolute;
    justify-content: space-between;
    bottom: 0px;
    display: flex;
    background: #fff;
    padding-left: 16px;
    box-sizing: border-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  
    /deep/.van-button {
      color: #0D171A !important;
      font-weight: 500;
      // width: 92px;
    }
  }
  
  .totalCount {
    color: @black;
    padding: 10px 16px 0 16px;
    font-size: 12px;
  }
</style>